import React from 'react'

import { Link } from 'gatsby'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Banner from '../components/banner'
import Foot from '../components/footer'

import '../styles/about.sass'

import 'react-multi-carousel/lib/styles.css'
import { responsive } from '../utils/responsive'

const AboutPage = () => {
    return (<Layout languageSwitchLink="/en/about">
        <SEO title="À propos" />
        <Banner class_suffix="deep" />
        <div className="about-container">
            <div className="about-content">
                <h3>Qui sommes-nous ?</h3>
                <p>
                    PolyCyber est une société technique de Polytechnique Montréal. Depuis 2013, elle œuvre afin de développer l'intérêt et les compétences de notre communauté étudiante pour la cybersécurité.
                </p>
                <h3>Nos missions</h3>
                <ul>
                    <li>Promouvoir les transferts de connaissances</li>
                    <li>Représenter Polytechnique Montréal dans les compétitions de cybersécurité</li>
                    <li>Tisser des liens avec l'industrie</li>
                    <li>Sensibiliser notre communauté aux enjeux liés à la cybersécurité</li>
                </ul>
                <h3>Nos activités</h3>
                <p>
                    Toute l'année, PolyCyber organise de nombreuses activités telles que des ateliers techniques, des conférences et des compétitions. De plus, nous participons fréquemment à des concours de type Capture the Flag où les participant(e)s doivent résoudre plusieurs défis et trouver une chaîne de caractères (appelée flag) qui atteste de la résolution du défi. Visitez notre blog pour voir les solutionnaires (<i>write-ups</i>) des compétitions auxquelles nous avons participées sur notre <Link to="/blog">blog</Link>.
                </p>
                <h3>Nos appuis</h3>
                <p>
                    Nos activités sont possibles grâce au grand soutien de nos commanditaires et à nos partenaires. Apprends-en davantage sur ce que font nos commanditaires et les profils qu'ils recherchent sur la page <Link to="/partners">Partenaires</Link>.
                </p>
                <a href="/join">
                    <button className="signup-button"> 
                    Rejoignez-nous !
                    </button>
                </a>
            </div>
        </div>

        <Foot></Foot>
    </Layout>)
}
export default AboutPage
